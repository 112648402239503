import {Helmet} from 'react-helmet-async';
import React from 'react';

import {PageType} from 'web/helpers/redux_client';
import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';

import {StoreState} from './controller';
import TermsLayout from '../components/terms_layout';

const ReturnsPage: PageType<StoreState> = () => {
  const terms = (
    <div>
      <h3>Returns, Refunds, and Credits Policy</h3>
      <h4>Returns</h4>
      <p>
        Good Eggs does not accept returns or exchanges of any products once it has been shipped.
        Good Eggs will only accept product returns when an order is cancelled, missed, or cannot be
        delivered, such as when a valid ID cannot be confirmed for alcohol product delivery. If
        there is a problem with an item in your order, please{' '}
        <span className="returns_terms__link">
          <a href="https://help.goodeggs.com/hc/en-us/requests/new">report your issue here</a>
        </span>
        . We will do everything we can to make it right.
      </p>
      <h4>Refunds and Credits</h4>
      <p>
        If you have any issues with your order, please contact Good Eggs Community Care team
        immediately to let us know. We recommend you do so as soon as possible since we cannot
        provide refunds, credits, or adjust charges on orders if you don’t report the issue within 7
        days from when the order was delivered.
      </p>
      <p>
        If you have missing products or an issue with your order, we, at our sole discretion, may
        give you credit for the product, and in some situations, issue a partial or full refund. We
        reserve the right to require a photograph for product quality complaints, before any refund
        or credit will be issued.
      </p>
      <p>
        If your order requires an attended delivery and we&apos;re not able to reach you, we will
        cancel the order on your behalf. If this happens, you will be given an account credit for
        the order amount, less any taxes, fees, and discounts. You may also be charged a
        cancellation fee. This fee helps cover the cost of disassembling the order to restock items
        that can be resold, and discarding fresh items that can&apos;t be resold (such as baked
        goods, produce, etc).
      </p>
      <p>
        If you receive a Good Eggs account credit, the credit should be applied to your account
        within 1 to 3 days of receiving confirmation from a Community Care team member.
      </p>
      <p>
        If you receive a refund, depending on your bank or credit card company, it can take between
        5 to 10 business days for the refund to process once it’s been issued. If you don&apos;t see
        a refund reflected within that time frame, please contact your bank or credit card company
        directly regarding their policies on refunds.
      </p>
      <p>
        If you have received a refund and need an updated receipt please contact the Good Eggs
        Community Care team and we’ll be glad to send that your way.
      </p>
    </div>
  );

  return (
    <MarketLayout disableUpcomingOrdersBanner>
      <Helmet>
        <title>Returns and Refunds | Good Eggs</title>
      </Helmet>
      <TermsLayout currentSlug="returns">{terms}</TermsLayout>
    </MarketLayout>
  );
};

ReturnsPage.pageName = 'Good Eggs Returns and Refunds';
ReturnsPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  return marketLayoutReducer(state, action);
};

export default ReturnsPage;
